<template>
  <div class="card page-404">
    <div class="card-body">
      <div class="row ">
        <div class="col-md-6">
          <img src="/img/404image.png" class="rounded img-fluid">
        </div>
        <div class="col-md-6">
          <p class="blockquote">
            The Venn Diagram
            Venn diagrams or set diagrams are diagrams that show all hypothetically possible
            logical relations between a finite collection of sets (groups of things).
            Venn diagrams were conceived around 1880 by John Venn.
            They are used in many fields, including set theory, probability,
            logic, statistics, computer science, and trying to visit web pages that don’
          </p>

          <div class="wrapper text-center">
            <router-link  tag="button" to="/about" class="btn btn-secondary btn-outline btn-lg">
                  Please take me to the Main Page
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
}
</script>

<style scoped lang="scss">
  .home-page {
    padding: 20px;
    border: 4px solid #00cae3;
    display: inline-block;
    text-transform: uppercase;
  }

  .page-404 {
    padding: 30px;
    min-height: 600px;
  }

</style>
