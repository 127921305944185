<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
          class="relative pt-16 pb-32 flex content-center items-center justify-center"
          style="min-height: 75vh;"
      >
        <div
            class="absolute top-0 w-full h-full bg-center bg-cover"
        >
          <span
              id="blackOverlay"
              class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pl-0">
                <h1 class="text-white font-semibold text-5xl">
                  Hey folks!
                </h1>
                 <p class="mt-4 text-lg text-gray-300 about-me text-left">
                   * My name is Andrei Bogdanov,
                   a dedicated software developer with almost 15 years of experience in IT,
                   13 of which involve commercial web development.
                </p>

                <p class="mt-4 text-lg text-gray-300 about-me text-left">
                  * Known for a friendly and open-minded attitude, I communicate and collaborate effectively with teammates
                </p>

                 <p class="mt-4 text-lg text-gray-300 about-me text-left">
                  * I focus on delivering results by using proven best practices and modern development approaches.
                </p>

                 <p class="mt-4 text-lg text-gray-300 about-me text-left">
                  * Staying on the cutting edge of web technologies is a priority, and I'm proficient with many of them
                </p>


                <p class="mt-4 text-lg text-gray-300 about-me text-left">
                  * Experienced in direct client interactions, project estimation, and developing solutions for industries such as
                  fintech, booking/CRM systems, online education, real estate, sports, and various startups....
                  <span class="blinking-cursor" style="color: #fff">|</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
            class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style="height: 70px;"
        >
          <svg
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
          >
            <polygon
                class="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="relative py-20 mb-6">
        <div
            class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px;"
        >
          <svg
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
          >
            <polygon
                class="text-white fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                  alt="..."
                  class="max-w-full rounded-lg shadow-lg"
                  src="@/assets/img/me.jpeg"
              />
            </div>

            <div class="w-full md:w-5/12 col-sm-6 ml-auto mr-auto px-4 mt-4">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Specialization</h3>

                <ul class="list-none mt-3">
                  <li
                      class="py-2">
                    <div class="flex items-center">
                      <div>
                        <h4 class="text-gray-600">
                          <ul class="">
                            <li class="py-2"><i class="text-gray-600">— Expert level in PHP software development under <b>Laravel</b></i></li>

                            <li class="py-2"><i>— Senior Full-Stack Developer (Laravel & Vue.js)</i></li>
                          </ul>

                        </h4>
                      </div>

                    </div>
                  </li>

                </ul>


                <div>
                  <p class="mt-4 text-lg about-me text-left">
                    I have a wide range of experience and have worked in various positions such as senior software developer, tech lead, and CTO.
                    My primary focus is backend development but I also has a great experience with frontend technologies,
                    which helps me easily collaborate with other developers and solve challenging issues
                  </p>
                </div>


                <h3 class="text-3xl font-semibold mt-8">Skills</h3>
                <ul class="list-none mt-3">
                  <li
                      v-for="(skill, index) in skills"
                      :key="index"
                      class="py-2">
                    <div class="flex items-center">
                      <div>

                        <span
                            class="text-md font-semibold inline-block py-1 px-2 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3"
                        >
                          <!-- <font-awesome-icon :icon="skill.icon" />-->
                          <font-awesome-icon :icon="'circle'"/>

                        </span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                          <b>{{ skill.title }}: </b>{{ skill.text }}
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="pb-20 relative bg-lightBlue-100 pt-20 xs:mt-20">
        <div
            class="bottom-auto top-0 left-0 bg-lightBlue-100 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: scaleY(-1); "
        >
          <svg
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
          >
            <polygon
                class="text-white fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center mt-20">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">

                <span
                    class="text-xl font-semibold inline-block py-3 px-5 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3">
                  <font-awesome-icon icon="circle"/>
                </span>

              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Publications
              </h3>
              <p
                  class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
                The book is a set of individual independent recipes.
                Each recipe shows how to do something useful with Yii explaining why it's done,
                how it's done, why it works and how exactly it works.
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto relative">
              <a target="_blank" class="absolute w-full h-full d-block z-50"
                 href="https://www.amazon.com/Yii-Application-Development-Cookbook-Third/dp/1785281763"></a>
              <div
                  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-600"
              >
                <img
                    alt="..."
                    width="50%"
                    src="@/assets/img/book-cover.png"
                    class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      class="absolute left-0 w-full block"
                      style="height: 95px; top: -94px;"
                  >
                    <polygon
                        points="-30,95 583,95 583,65"
                        class="text-blueGray-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Yii2 Application Development Cookbook. Third Edition
                  </h4>

                  <p class="text-md font-light mt-2 text-white">
                    Discover 100 useful recipes that will bring the best out of the Yii2 framework and be on the
                    bleeding edge of web development today
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="relative py-20">
        <div
            class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px;"
        >
          <svg
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
          >
            <polygon
                class="text-white fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-6/12  ml-auto mr-auto px-4">
              <img
                  alt="..."
                  class="max-w-full rounded-lg shadow-lg"
                  src="@/assets/img/upwork.png"
              />
            </div>

            <div class="w-full md:w-4/12 col-sm-6 mt-10 ml-auto mr-auto px-4">

               <span
                   v-if="false"
                   class="text-xl font-semibold inline-block py-3 px-5 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3">
                  <font-awesome-icon icon="book"/>
               </span>

              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Upwork profile</h3>
                <ul class="list-none mt-3">

                  <li
                      class="py-2">
                    <div class="flex items-center">
                      <div>
                      <span
                        class="text-md font-semibold inline-block py-1 px-2 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3">
                        <font-awesome-icon :icon="'circle'"/>
                      </span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                           More than 8000+ hours remote experience with about 30 clients
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li
                      class="py-2">
                    <div class="flex items-center">
                      <div>
                      <span
                          class="text-md font-semibold inline-block py-1 px-2 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3">
                        <font-awesome-icon :icon="'circle'"/>
                      </span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                          About 30 clients different clients across USA, Europe, Canada, Australia
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li
                      class="py-2">
                    <div class="flex items-center">
                      <div>
                      <span
                          class="text-md font-semibold inline-block py-1 px-2 uppercase rounded-full bg-indigo-200 text-white active:bg-teal-600 mr-3">
                        <font-awesome-icon :icon="'circle'"/>
                      </span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                          Had experienced with different technologies such as <b>python/django, go lang, nodejs</b>,
                          expert level in PHP software development under <b>Laravel</b>
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";


export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    FooterComponent
  },
  data: () => ({
    skills: [
      {
        title: 'Backend',
        text: 'RESTful API, Native PHP 7.x/8.x, MySQL/MariaDB, PostgreSQL, MongoDB',
        icon: ['fas', 'cube']
      },
      {
        title: 'Data Services',
        text: 'Redis, RabbitMQ, Amazon S3/SES, Kafka, Laravel Octane',
        icon: ['fas', 'cube']
      },

      {
        title: 'Frameworks',
        text: 'Laravel (primary framework)',
        icon: 'cubes'
      },
      {
        title: 'Admin Panels',
        text: 'Laravel Filament, Laravel Nova, with experience in building custom solutions',
        icon: 'stream'
      },
      {
        title: 'Approaches',
        text: 'SOLID, KISS, DRY, TDD, DDD',
        icon: 'stream'
      },
      {
        title: 'Frontend',
        text: 'Vue.js + Nuxt, Atomic Design, TypeScript, Webpack',
        icon: ['fab', 'js']
      },
      {
        title: 'DevOps',
        text: 'CI/CD, GitLab CI, GitHub Actions',
        icon: 'poll-h'
      },
      {
        title: 'Testing',
        text: 'TDD, Unit and Acceptance Testing with PHPUnit',
        icon: 'check-double'
      },
      {
        title: 'Servers',
        text: 'GNU/Linux (CentOS for web servers), managing own VDS',
        icon: 'layer-group'
      },
      {
        title: 'Tools',
        text: 'macOS, PHPStorm, Laravel Idea, using GitHub Copilot to speed up coding and ChatGPT for generating new ideas',
        icon: 'layer-group'
      },
      {
        title: 'Version Control',
        text: 'GitHub, GitLab',
        icon: 'code-branch'
      },
      {
        title: 'Communication',
        text: 'Jira, Slack, Zoom, Telegram',
        icon: 'stream'
      }
    ]
  })

}
</script>

<style>
.blinking-cursor {
  font-weight: 100;
  font-size: 20px;
  color: #fff;
  -webkit-animation: 2s blink step-end infinite;
  -moz-animation: 2s blink step-end infinite;
  -ms-animation: 2s blink step-end infinite;
  -o-animation: 2s blink step-end infinite;
  animation: 2s blink step-end infinite;
}

* {
              font-family: "Work Sans", sans-serif;
              font-optical-sizing: auto;
              font-weight: 300;
              font-style: normal;
            }


@keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: rgba(212, 212, 216, var(--tw-text-opacity));
  }
}

@-webkit-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: rgba(212, 212, 216, var(--tw-text-opacity));
  }
}

@-ms-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: rgba(212, 212, 216, var(--tw-text-opacity));
  }
}

@-o-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: rgba(212, 212, 216, var(--tw-text-opacity));
  }
}

.bg-cover {
  background-image: url("../../assets/img/16.jpg")
}

</style>
