import Vue from 'vue'
import Router from 'vue-router'
import About from '@/components/pages/About'
import NotFound from '@/components/pages/NotFound'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'About',
      component: About
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
    }, {
      path: '*',
      redirect: '/404'
    }
  ]
})

export default router
