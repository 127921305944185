<template>
  <footer class="relative bg-gray-300 pt-8 pb-6">
    <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px;"
    >
      <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
      >
        <polygon
            class="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
          <h5 class="text-lg mt-0 mb-2 text-gray-700">
            Find me on socials
          </h5>
          <div class="mt-6">
            <button
                v-for="(social, index) in socials"
                :key="index"
                @click="clickSocial(social.url)"
                :class="social.class"
                class="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
            >
              <span class="flex">
                <font-awesome-icon :icon="social.icon"/>
              </span>

            </button>

          </div>
        </div>
      </div>

      <div class="w-full mt-10 px-4">
        <div class="flex flex-wrap items-top mb-6">
          <div class="w-full ml-auto">
              <span
                  class="text-md font-semibold inline-block uppercase rounded-full"
              >Useful Links</span
              >
            <ul class="list-unstyled mt-5">
              <li class="mt-2" v-for="(link, index) in links" :key="index">
                <a
                    class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    :href="link.url"
                >{{ link.text }}</a
                >
              </li>

            </ul>
          </div>

        </div>
      </div>

      <hr class="my-6 border-gray-400"/>
      <div
          class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-600 font-semibold py-1">
            © {{ date }} made with <span class="text-pink-600"><font-awesome-icon icon="heart"/></span> by Andrei
            Bogdanov.

          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
      links: [
        {
          name: 'unsplash.com',
          url: 'https://unsplash.com/',
          text: 'Free (do whatever you want) high-resolution photos.'
        },
        {
          name: 'regex101.com',
          url: 'https://regex101.com/',
          text: 'Online regex tester and debugger',
        },
        {
          name: 'lea.verou.me',
          url: 'http://lea.verou.me/',
          text: 'Life at the bleeding edge of web standards'
        },
        {
          name: 'www.2amigos.us',
          url: 'http://www.2amigos.us/',
          text: '2Amigos Consulting Group. Web Application and Mobile Development',
        },
        {
          text: 'We are a global marketplace for artists, photographers, and the world\'s most iconic brands',
          url: 'http://pixels.com',
          name: 'pixels.com'
        },
        {
          url: 'http://www.flaticon.com/',
          name: 'flaticon.com',
          text: 'The largest database of free icons available in PNG, SVG, EPS, PSD and BASE 64 formats.',
        },
        {
          name: 'wireframe.cc',
          url: 'http://wireframe.cc',
          text: 'Design Interactive Prototypes For Web'
        },
        {
          name: 'leanpub.com',
          url: 'https://leanpub.com/',
          text: ' Authors and publishers use Leanpub to publish amazing in-progress and completed books'
        },
        {
          name: 'coderwall.com',
          url: 'https://coderwall.com/',
          text: 'The latest development and design tips, tools, and projects from our developer community.'
        },
        {
          name: 'scotch.io',
          url: 'https://scotch.io/',
          text: 'Code neat and clean'
        },

      ],
      socials: [
        {
          class: 'text-pink-400',
          icon: ['fab', 'instagram'],
          url: 'https://instagram.com/jehkinen'
        },
        {
          class: 'text-blue-500',
          icon: ['fab', 'linkedin'],
          url: 'https://www.linkedin.com/in/jehkinen'
        },
        {
          class: 'text-blue-400',
          icon: ['fab', 'behance'],
          url: 'https://www.behance.net/jehkinen'
        },
        {
          class: 'text-gray-500',
          icon: ['fab', 'github'],
          url: 'https://github.com/jehkinen'
        },

      ]
    }
  },
  methods: {
    clickSocial(url) {
      window.open(url);
    },
  },
}
</script>
